import React, { useState, useEffect } from 'react';


import Rowena from '../assets/Rowena.png';
import Betty from '../assets/Betty.png';
import Tim from '../assets/Tim.jpeg';
import Kayla from '../assets/Kayla.png';

const testimonials = [
  {
    quote: "I couldn't have started my company without working with Tyler to flesh out the business concept and model. I was in the beginning phases with no clue where to start. He took the time to understand the industry and our place in the market. He provided actionable digital marketing plans vital to a new e-comm company and connected me to all the right partners that, saved me an immense amount of time and money. We launched successfully to buying customers from day one!",
    author: "Rowena Lei-Grant",
    profilePicture: Rowena,
    website: "AugustSage"
  },
  {
    quote: "I've been wanting to build my business for years - and within one week of talking with Tyler I had a company name, fully designed brand, a working website, and even automated lead routing to start building my funnel! Incredibly fast, efficient, and cost me nothing!",
    author: "Betty Haila",
    profilePicture: Betty,
    website: "HolboxConcierge"
  },
  {
    quote: "Starting truepipe was easy, but I ran into roadblocks almost immediately which kept me from officially launching my website and SaaS product. I gave up for almost a year before meeting Tyler and his team. Within a month we had a working product and are now officially in business!",
    author: "Tim Shaw",
    profilePicture: Tim,
    website: "Truepipe.ai"
  },
  {
    quote: "Building a personal training business is hard. It's super saturated and I needed a better way to track my online presence to start monetizing some of my free viewers. Within two weeks of bringing my challenges to Tyler we had a plan in place and a working solution! Couldn't recommend them enough!",
    author: "Kayla LeDoux",
    profilePicture: Kayla,
    website: "GuavaGoodDay"
  },
];

function Testimonials() {
  const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((i) => (i + 1) % testimonials.length);
    }, 6000); // Cycle every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setCurrentTestimonial(testimonials[index]);
  }, [index]);

  return (
    <div className="testimonials bg-[#111] text-white p-10 text-center flex flex-col items-center justify-center">
      <p className={`quote text-xl ${index === currentTestimonial ? 'active' : ''}`}>"{currentTestimonial.quote}"</p>
      <img className="profile-picture w-auto h-auto rounded-full bg-gray-300 mt-4" src={currentTestimonial.profilePicture} alt="Profile Pic" />
      <p className={`author mt-4 font-bold ${index === currentTestimonial ? 'active' : ''}`}>{currentTestimonial.author}</p>
      <a href={currentTestimonial.website} className={`website mt-2  ${index === currentTestimonial ? 'active' : ''}`}>{currentTestimonial.website}</a>
    </div>
  );
}

export default Testimonials;
