import React, { useState, useEffect } from 'react';
import backgroundVideo from '../assets/video (2160p).mp4'; // Update with the path to your background video

const rotatingTexts = [
  "increase bookings", // The initial text
  "convert more customers",
  "improve SEO",
  "automate lead routing",
  "with web development",
  "build a brand",
  "automate sales and marketing",
  "be more efficient.",
];

function Header() {
  const [currentText, setCurrentText] = useState(rotatingTexts[0]);
  const [textIndex, setTextIndex] = useState(0);
  const [isTextVisible, setIsTextVisible] = useState(true); // New state variable

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTextVisible(false); // Hide text before changing it
      setTimeout(() => setTextIndex((current) => (current + 1) % rotatingTexts.length), 500); // Change text after 0.5 seconds
    }, 3000); // Change text every 2 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    setCurrentText(rotatingTexts[textIndex]);
    setTimeout(() => setIsTextVisible(true), 500); // Show text after 0.5 seconds
  }, [textIndex]);

  return (
    <header className="header relative text-white text-center p-4 md:w-auto md:h-auto">
      <video autoPlay loop muted playsInline preload="auto" className="absolute w-full h-full object-cover" style={{ position: 'absolute', zIndex: '-1' }}>
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
      <div className="hero-container">
        <div className="herotext-container">
        <h1 className="text-4xl font-bold mb-2">We help entrepreneurs</h1>
        <h2 className={`text-4xl font-bold text-teal-400 rotating-text ${isTextVisible ? 'visible' : ''}`}>{currentText}</h2>
        </div>
        <div className="cta-container">
          <a href="https://meetings.hubspot.com/tyler-klug1" className="cta-button mx-auto border-2 border-teal-400 text-teal-400">Schedule a Call</a>
          <a href="#ContactUs" className="cta-button cta-button-alt mx-auto" style={{background: 'linear-gradient(45deg, #00F6FF, #B3FFAA)'}}>Share Your Idea</a>
        </div>
      </div>
    </header>
  );
}

export default Header;