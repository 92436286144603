import React from 'react';
import Header from './Header';
import Testimonials from './Testimonials';
import Services from './Services';
import Projects from './Projects';
import ContactPage from './ContactUs';
import Footer from './Footer';
import '../App.css';

function HomePage() {
  return (
    <>
      <Header />
      <Projects />
      <Testimonials />
      <Services />
      <ContactPage />
      <Footer />
    </>
  );
}

export default HomePage;