// Footer.js
// Footer component for displaying contact information with icons
// v1.0.0

import React from 'react';
import { FiPhone, FiMail, FiMapPin } from 'react-icons/fi';

const Footer = () => {
  return (
    <footer className="bg-[#111] text-white">
      <div className="container mx-auto px-4 py-8 text-center">
        <div className="flex flex-col md:flex-row justify-center items-center gap-6">
          <div className="flex items-center gap-2">
            <FiPhone className="text-teal-400" />
            <span>+1.785.577.1230</span>
          </div>
          <div className="flex items-center gap-2">
            <FiMail className="text-teal-400" />
            <span>hello@tkcgroup.co</span>
          </div>
          <div className="flex items-center gap-2">
            <FiMapPin className="text-teal-400" />
            <span>PNW - OSLO - KCMO</span>
          </div>
        </div>
        <div className="mt-4">
          <p>© {new Date().getFullYear()} TKC Group LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
