// tkcgroup/src/components/ContactUs.js
// Contact form page for user inquiries with Formspree integration.
// v1.0.3

import React from 'react';

const ContactPage = () => {
  // Replace with your Formspree form URL
  const formURL = "https://formspree.io/f/mzbnppjn";

  return (
    <div className="contact-container" id="ContactUs">
      <div className="contact-content">
        <h1 className="section-header">CONTACT US</h1>
        <p className="contact-subtext">
          Please use the form below to get in touch. Any context you can add about your business or idea would be helpful. We will get back to you within one business day.
        </p>
        <form action={formURL} method="POST" className="contact-form">
          <input type="text" name="name" placeholder="Name" className="form-input custom-focus" />
          <input type="text" name="company" placeholder="Company" className="form-input custom-focus" />
          <input type="email" name="_replyto" placeholder="Email" className="form-input custom-focus" />
          <input type="tel" name="phone" placeholder="Phone Number" className="form-input custom-focus" />
          <textarea name="message" placeholder="Your message" className="form-input form-textarea custom-focus"></textarea>
          <button type="submit" className="submit-button">Send message</button>
        </form>
        <div className="contact-info">
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
