import React from 'react';
import { ReactComponent as AlpenBrosGreyScale } from '../assets/AlpenBrosGreyScale.svg';
import { ReactComponent as AlpenglowGreyScaleSVG } from '../assets/AlpenglowGreyScaleSVG.svg';
import { ReactComponent as ChannelMixGreyScale } from '../assets/ChannelMixGreyScale.svg';
import { ReactComponent as ShinyDocsGreyScale } from '../assets/ShinyDocsGreyScale.svg';
// import other SVGs similarly...

function Projects() {
  // Array for logos, replace with your actual logos
  const logos = [
    { id: 1, src: <img src={require('../assets/AugustSage_Greyscale.png')} className="project-logo" alt="logo" /> },
    { id: 2, src: <img src={require('../assets/CravnGreyScale.png')} className="project-logo" alt="logo" /> },
    { id: 3, src: <img src={require('../assets/TruePipeGreyScale.png')} className="project-logo" alt="logo" /> },
    { id: 4, src: <img src={require('../assets/GGDGreyScale.png')} className="project-logo" alt="logo" /> },
    { id: 5, src: <AlpenglowGreyScaleSVG className="project-logo" /> },
    { id: 6, src: <img src={require('../assets/HolboxConciergeGreyScale.png')} className="project-logo" alt="logo" /> },
    { id: 7, src: <img src={require('../assets/ConectGreyScale.png')} className="project-logo" alt="logo" /> },
    { id: 8, src: <ChannelMixGreyScale className="project-logo" /> },
    { id: 9, src: <AlpenBrosGreyScale className="project-logo" /> },
    { id: 10, src: <ShinyDocsGreyScale className="project-logo" /> },
    // use other SVGs similarly...
  ];

  return (
    <div className="parent-container">
      <div className="section-header">
        RECENT PROJECTS
      </div>
      <div className="projects-grid">
        {logos.map((logo) => (
          <div key={logo.id} className="projects-item">
            {logo.src}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
