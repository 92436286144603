import React from 'react';
// You need to install react-icons library to ensure these imports work
// You can install it using the following command: npm install react-icons
import { FaRocket, FaCode, FaChartBar, FaCalendarCheck, FaCar } from 'react-icons/fa';

const services = [
  {
    Icon: FaRocket,
    title: 'SEO Mastery',
    description: 'Increase your Google ranking and drive organic traffic to your website with our tailored SEO strategies.',
  },
  {
    Icon: FaCode,
    title: 'Website Development',
    description: 'Create a stunning and functional website that showcases your photography skills and converts visitors into clients.',
  },
  {
    Icon: FaChartBar,
    title: 'Marketing Channel Analysis',
    description: 'Identify and optimize the marketing channels that bring in the most impressions, clicks, and conversions for your business.',
  },
  {
    Icon: FaCalendarCheck,
    title: 'Event Tracking',
    description: 'Make data-driven decisions with Google Tag Manager\'s event tracking, ensuring you\'re informed about user behavior on your website.',
  },
  {
    Icon: FaCar,
    title: 'Zapier Automation',
    description: 'Streamline your business processes with custom Zapier automations, saving you time and maximizing efficiency.',
  },
];

function Services() {
  return (
    <div className="parent-container">
      <div className="section-header">
        OUR SERVICES
      </div>
      <div className="section-body">
        {services.map((service, index) => (
          <div key={index} className="services-content">
            <service.Icon style={{ width: '35px', height: 'auto', paddingBottom: '.75em', color: 'linear-gradient(45deg, #00F6FF, #B3FFAA)'}} />
            <h3 style={{ fontWeight: 'bold' }}>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );

}

export default Services;
