import React from 'react';
import logo from '../assets/ColorizedLogo_BlueGreen.svg'; // Replace with your logo path

function Navbar() {
  return (
    <div className="Navbar" style={{display: 'flex', margin: '0', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'black'}}>
        <div className="brand-block">
          <img src={logo} alt="TKC Logo" style={{width: 'auto', maxHeight: '70px', minHeight: '44px', marginLeft: '1em' }}/>
          
        </div>
        <div className="contact-button">
          <button onClick={() => {document.getElementById('ContactUs').scrollIntoView({behavior: "smooth"});}}>
            Contact
          </button>
        </div>
    </div>
  );
}

export default Navbar;
